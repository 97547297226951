import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { Navbar } from '../common/Navbar/Navbar'
import { Footer } from '../common/Footer/Footer'
import FooterNew from '../common/FooterNew/FooterNew'
import {
  TrackingPopup,
  StartUsagePopup,
  NotificationPopup,
} from '../common/Popups/NativePermissions'
import MobileMenu from '../common/MobileMenu/MobileMenu'
import {
  judgeMePlatform,
  judgeMePublicToken,
  judgeMeShopDomain,
} from '../../constants/constants'

export interface ILayoutProps {
  children?: any
  currentPage?: any
  isWebView?: any
  notFixedHeader?: boolean
  isMobileMenu?: boolean
  showReviews?: boolean
}

const LayoutWithReviews = ({
  children,
  currentPage,
  isWebView,
  notFixedHeader,
  isMobileMenu,
  showReviews,
}: ILayoutProps) => {
  const [shopPopups, setShowPopups] = useState(false)
  const [popupsQueue, setPopupQueue] = useState(0)
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const router = useRouter()
  const { isShowRequestForPermissions, osType } = router.query

  const reloadJudgeMe = async () => {
    // @ts-ignore: Unreachable code error
    let jdgm = window.jdgm || {}
    jdgm.SHOP_DOMAIN = judgeMeShopDomain
    jdgm.PLATFORM = judgeMePlatform
    jdgm.PUBLIC_TOKEN = judgeMePublicToken
    if (window) {
      // @ts-ignore: Unreachable code error
      window.jdgm = {
        // @ts-ignore: Unreachable code error
        ...window.jdgm,
        ...jdgm,
      }
      // @ts-ignore: Unreachable code error
      if (window.jdgmCacheServer) {
        // @ts-ignore: Unreachable code error
        window.jdgmCacheServer?.reloadAll()
      }
    }
  }

  useEffect(() => {
    if (isWebView) {
      setShowPopups(Boolean(isShowRequestForPermissions))
    }
    reloadJudgeMe()
    setIsScriptLoaded(true)
  }, [])

  const changeAndPermissionHandler = (
    permissionName: string,
    page?: number
  ) => {
    if (permissionName === 'redirect') {
      window.ReactNativeWebView?.postMessage(permissionName)
      setShowPopups(false)
    } else {
      window.ReactNativeWebView?.postMessage(permissionName)
      if (page) setPopupQueue(page)
    }
  }

  if (isWebView) {
    return (
      <>
        {isScriptLoaded && showReviews && (
          <>
            <Head>
              <script
                id="widget_preloader"
                data-cfasync="false"
                type="text/javascript"
                async
                src="https://cdn.judge.me/widget_preloader.js"
              ></script>
              <script
                id="widget_installed"
                data-cfasync="false"
                type="text/javascript"
                async
                src="https://cdn.judge.me/assets/installed.js"
              ></script>
            </Head>
          </>
        )}
        <main>{children}</main>

        {shopPopups ? (
          osType === 'ios' ? (
            <>
              {popupsQueue === 0 && (
                <TrackingPopup
                  nextPopup={changeAndPermissionHandler}
                  closePopups={setShowPopups}
                />
              )}
              {popupsQueue === 1 && (
                <NotificationPopup
                  nextPopup={changeAndPermissionHandler}
                  closePopups={setShowPopups}
                />
              )}
              {popupsQueue === 2 && (
                <StartUsagePopup
                  closePopups={setShowPopups}
                  nextPopup={changeAndPermissionHandler}
                />
              )}
            </>
          ) : (
            <StartUsagePopup
              closePopups={setShowPopups}
              nextPopup={changeAndPermissionHandler}
            />
          )
        ) : null}
      </>
    )
  }
  return (
    <>
      {isScriptLoaded && showReviews && (
        <Head>
          <script
            id="widget_preloader"
            data-cfasync="false"
            type="text/javascript"
            async
            src="https://cdn.judge.me/widget_preloader.js"
          ></script>
          <script
            id="widget_installed"
            data-cfasync="false"
            type="text/javascript"
            async
            src="https://cdn.judge.me/assets/installed.js"
          ></script>
        </Head>
      )}
      <Navbar currentPage={currentPage} notFixedHeader={notFixedHeader} />
      <main>
        {children}
        {isMobileMenu && <MobileMenu currentPage={currentPage} />}
      </main>
      <FooterNew />
    </>
  )
}

export default LayoutWithReviews
